<template>
  <div>

    <div class="vx-row">
      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Due Date</label>
        <datepicker v-model="estimatedDueDate"
          placeholder="Select Date"
          name="estimated_due_date"
          color="dark"
          :format="datePicker.format"
          :disabled-dates="datePicker.disabledDatesForDueDate"
          v-validate="'required'"/>

          <span class="text-danger text-sm mt-2" v-show="errors.has('estimated_due_date')">
            {{ errors.first('estimated_due_date') }}
          </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <vs-input :value="estimatedDueDate | pregnancyWeeks"
          class="w-full"
          name="pregnancy_week"
          color="dark"
          label="Pregnancy Weeks"
          readonly/>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Current Product Using</label>
        <vs-input v-if="product_disabled"
                  :value="child_product"
                  class="w-full"
                  readonly />
        <v-select v-else v-model="child.product"
                  class="w-full"
                  name="product"
                  color="dark"
                  :options="optionsProduct"
                  :reduce="product => product.slug"
                  :disabled="product_disabled" />
        <span class="text-danger text-sm block mt-2" v-show="errors.has('product')">
          {{ errors.first('product') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2" v-if="child.is_master && currentOutboundStage">
        <vs-input :value="currentOutboundStage.title"
          class="w-full"
          label="Current Stage"
          name="current_stage"
          readonly/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import formMixin from '@/mixins/member/formMixin'

export default {
  name: 'MemberChildrenEdd',
  mixins: [formMixin],
  filters: {
    pregnancyWeeks (value) {
      if (!value) {
        return ''
      }

      const week = 40 - moment(value).diff(new Date(), 'weeks')

      if (week > 40) {
        return 'Over due date'
      }

      if (week < 0) {
        return 'Invalid value'
      }

      return `${week} weeks`
    },
  },
  computed: {
    estimatedDueDate: {
      get () {
        return this.child.estimated_due_date
      },
      set (edd) {
        this.child.estimated_due_date = this.$moment(edd).format('YYYY-MM-DD')
      },
    },
    child_product () {
      const product_slug = this.child.product
      const result = this.optionsProduct.filter(function (p) {
        if (p.slug === product_slug) {
          return p
        }
      })
      if (result.length > 0) {
        return result[0].label
      } else {
        return ''
      }
    },
    product_disabled () {
      return this.child.product !== null
    },
  },
}
</script>
